const syspharListGroup = [
  [{ name: "Boas vindas" }],
  [
    { name: "Revisão Sistemática e Meta-Análise" },
    { name: "O que é Revisão Sistemática?" },
    { name: "O que é Meta-Análise?" },
    { name: "O que é Revisão Sistemática e Meta-Análise viva?" },
    { name: "Revisões Sistemáticas e Meta-análises em Farmacologia" },
  ],
  [
    { name: "Plano geral das Revisões Sistemáticas e Meta-análises" },
    { name: "Planejamento, implementação, publicação e atualização" },
  ],
  [
    { name: "Fase 0: Antes de começar" },
    { name: "Relevância do estudo" },
    { name: "Preparando a equipe para começar" },
  ],
  [
    { name: "Fase 1: Elaborando a pergunta da revisão" },
    { name: "Ferramenta PICO" },
    { name: "Outras ferramentas mnemônicas" },
  ],
  [
    { name: "Fase 2: Elaborando o protocolo e revisão" },
    { name: "Modelos de protocolos" },
    { name: "Planejando a estratégia de busca" },
    { name: "Planejando a estratégia de seleção" },
    { name: "Planejando a extração de dados" },
    { name: "Planejando a Meta-Análise" },
    { name: "Planejando a publicação dos resultados" },
    { name: "Planejando a revisão sistemática viva" },
  ],
  [
    { name: "Fase 3: Registrando o protocolo de revisão" },
    { name: "Plataformas públicas de registro" },
  ],
  [
    { name: "Fase 4: Implementação do protocolo de revisão" },
    { name: "Softwares, scripts e outros recursos gratuitos" },
  ],
  [
    { name: "Fase 5: Publicação dos resultados da revisão" },
    { name: "Onde e como publicar?" },
  ],
  [
    { name: "Fase 6: Mantendo a revisão sistemática viva" },
    { name: "Atualização da revisão sistemática" },
  ],
  [
    { name: "Sobre" },
    { name: "SysPhar" },
    { name: "Como citar esta ferramenta?" },
    { name: "Nossa equipe" },
    { name: "Apoiadores" },
    { name: "Contato" },
  ],
]
export const syspharListGroupEn = [
  [{ name: "Welcome" }],
  [
    { name: "Systematic Review and Meta-Analysis" },
    { name: "What is Systematic Review?" },
    { name: "What is Meta-analysis?" },
    { name: "What is Systematic Review and Living Meta-Analysis?" },
    { name: "Systematic Reviews and Meta-Analyses in Pharmacology" },
  ],
  [
    { name: "General Plan of Systematic Reviews and Meta-Analysis" },
    { name: "Planning, implementation, publication, and maintenance" },
  ],
  [
    { name: "Phase 0: Before Starting" },
    { name: "Relevance of the study" },
    { name: "Preparing the team to begin" },
  ],
  [
    { name: "Phase 1: Drafting the Review Question" },
    { name: "PICO Tool" },
    { name: "Other mnemonic tools" },
  ],
  [
    { name: "Phase 2: Drafting the Revision Protocol" },
    { name: "Protocol templates" },
    { name: "Planning the search strategy" },
    { name: "Planning the selection strategy" },
    { name: "Planning data extraction" },
    { name: "Planning Meta-Analysis" },
    { name: "Planning the publication of results " },
    { name: "Planning the living systematic review" },
  ],
  [
    { name: "Phase 3: Registering the Review Protocol" },
    { name: "Public registration platforms" },
  ],
  [
    { name: "Phase 4: Implementation of the Review Protocol" },
    { name: "Free software, scripts, and other features" },
  ],
  [
    { name: "Phase 5: Publication of the Results of the Review" },
    { name: "Where and how to publish?" },
  ],
  [
    { name: "Phase 6: Keeping Living Systematic Review" },
    { name: "Update and publish" },
  ],
  [
    { name: "About" },
    { name: "SysPhar" },
    { name: "How to quote the tool?" },
    { name: "Our team" },
    { name: "Supporters" },
    { name: "Contact" },
  ],
]

export default syspharListGroup
