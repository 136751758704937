import * as React from "react"
import GradientJumbo from "../components/gradient-jumbo"
import Layout from "../components/layout"
import Contact from "../components/sections/contact"
import SyspharTabs from "../components/sections/sysphar/sysphar_tabs"
import Seo from "../components/seo"

const SyspharPage = props => (
  <Layout language="pt" pathname={props.location.pathname}>
    <Seo
      title="SysPhar"
      lang="pt"
      description="No guia SysPhar você encontra informações, links, artigos, recursos e ferramentas úteis para conduzir suas revisões sistemáticas e meta-análises em Farmacologia"
    />
    <GradientJumbo subtitle="SysPhar" title="Um guia para Farmacologistas" />
    <SyspharTabs language="pt" />
    <Contact language="pt" />
  </Layout>
)

export default SyspharPage
