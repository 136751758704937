import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, ListGroup, Row, Tab } from "react-bootstrap"
import { siteMetadata } from "../../../../gatsby-config"
import syspharListGroup from "../../../data/syspharListGroup"
import { getPTCitation } from "../../../utils"
import ListGroupWikiItem from "../../listgroup/ListGroupWikiItem"

export default function SyspharTabs() {
  return (
    <section className="container" id="wiki">
      <Tab.Container defaultActiveKey="#00">
        <Row>
          <Col sm={4}>
            <ListGroup id="list-group">
              <ListGroupWikiItem array={syspharListGroup} />
            </ListGroup>
          </Col>
          <Col sm={1}></Col>
          <Col sm={6}>
            <Tab.Content className="mt-3 mt-md-0">
              <Tab.Pane eventKey="#00">
                <h3 className="fw-bold">Boas vindas!</h3>
                <p>Olá, farmacologistas!</p>
                <p>
                  Bem-vindos ao wiki{" "}
                  <span className="fw-bold">
                    SysPhar Revisões Sistemáticas em Farmacologia
                  </span>
                </p>
                <p>
                  No guia <span className="fw-bold">SysPhar</span> você encontra
                  informações, links, artigos, recursos e ferramentas úteis para
                  conduzir suas revisões sistemáticas e meta-análises em
                  Farmacologia.
                </p>
                <p>
                  Navegue pelas seções para descobrir como planejar, conduzir e
                  concluir uma revisão sistemática, uma meta-análise ou uma
                  revisão sistemática viva.{" "}
                </p>
                <p>
                  Use a barra de índice no lado esquerdo da tela para navegar
                  pelas etapas do nosso guia.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#10">
                <h3 className="fw-bold">1 Revisão Sistemática e Meta-análise</h3>
                <h3>1.1 O que é Revisão Sistemática?</h3>
                <span className="fw-bold">Revisões sistemáticas* </span>são
                revisões da literatura realizadas com os objetivos de
                identificar, obter, filtrar, avaliar e sintetizar estudos
                científicos para responder a uma questão de pesquisa. Usam
                métodos transparentes, baseados em decisões tecnicamente
                fundamentadas e imparciais; portanto, são reproduzíveis.
                <StaticImage
                  className="my-3"
                  src="../../../assets/images/wikis/pt/sysphar/FIG_1.png"
                  alt="FIG_1"
                />
                <p>
                  <figcaption className="figure-caption">
                    Figura 1. As revisões sistemáticas, com ou sem meta-análise,
                    são um tipo de literatura secundária pois reúnem dados da
                    literatura primária.
                  </figcaption>
                </p>
                <p>
                  Referências sugeridas: {" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://pubmed.ncbi.nlm.nih.gov/9054282/"

                  >
                    Cook, Mulrow e Haynes (1997);
                  </a>
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://pubmed.ncbi.nlm.nih.gov/24479036/"

                  >
                    Gopalakrishnan e Ganeshkumar (2013)
                  </a>
                  ;{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://pubmed.ncbi.nlm.nih.gov/29169792/"
                  >
                    Delgado-Rodríguez e Sillero-Arenas (2018)
                  </a>
                  .
                </p>
                <p>
                  *Nota: uma revisão sistemática pode ou não ser seguida de uma
                  meta-análise.
                </p>

                <h3>1.2 O que é meta-análise?</h3>
                <p>
                  <b>Meta-análise</b>* é um método estatístico utilizado para combinar
                  os resultados quantitativos de dois ou mais estudos primários
                  independentes, sintetizando-os em um resultado único.
                </p>
                <p>
                  Referências sugeridas: {" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3049418/"

                  >
                    Haidich (2010)
                  </a>
                  ;{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://pubmed.ncbi.nlm.nih.gov/24099992/"

                  >
                    Vesterinen et al. (2014)
                  </a>
                  ;{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://academic.oup.com/bja/article/117/4/428/2410577"

                  >
                    Møller e Myles (2016)
                  </a>
                  ;{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://pubmed.ncbi.nlm.nih.gov/28891724/"

                  >
                    Wormald e Evans (2018)
                  </a>
                  .
                </p>

                <h3>1.3 O que é revisão sistemática e meta-análise viva?</h3>
                <p>
                  As revisões sistemáticas e meta-análises podem rapidamente se
                  tornar obsoletas, às vezes antes mesmo de serem finalizadas.
                </p>
                <p>
                  As abordagens de atualização que incorporam novas evidências
                  relevantes à medida que estas se tornam disponíveis são
                  chamadas de{" "}
                  <b>“revisões sistemáticas e meta-análises vivas”</b>, ou
                  living systematic reviews (LSR), em inglês.
                </p>
                <p>
                  Referências sugeridas: {" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3928029/"

                  >
                    Elliott et al. (2014)
                  </a>
                  ;{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4955793/"

                  >
                    Garner et al. (2016)
                  </a>
                  ;{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://pubmed.ncbi.nlm.nih.gov/28912002/"

                  >
                    Elliott et al. (2017)
                  </a>
                  ;{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://pubmed.ncbi.nlm.nih.gov/34550587/"

                  >
                    Simmonds et al. (2022)
                  </a>
                  .
                </p>

                <h3>1.4 Revisões sistemáticas e meta-análises em farmacologia</h3>
                <p>
                  No contexto da Farmacologia, estudos únicos nem sempre são
                  suficientes para fornecer informações confiáveis a favor ou
                  contra o uso de um fármaco em um experimento, projeto ou no
                  desenvolvimento de um medicamento.
                </p>

                <StaticImage
                  className="my-3"
                  src="../../../assets/images/wikis/pt/sysphar/FIG_2.png"
                  alt="FIG_2"
                />
                <p>
                  <figcaption className="figure-caption">
                    Figura 2. As revisões sistemáticas e meta-análises em
                    farmacologia podem trazer informações úteis para a descoberta
                    e o desenvolvimento de novos fármacos.
                  </figcaption>
                </p>

                Assim, as revisões sistemáticas – com ou sem meta-análise –
                são úteis para reunir, combinar e sintetizar em um único
                estudo as evidências relevantes para o processo decisório.
                <StaticImage
                  className="my-3"
                  src="../../../assets/images/wikis/pt/sysphar/FIG_3.png"
                  alt="FIG_3"
                />
                <p>
                  <figcaption className="figure-caption">
                    Figura 3. As revisões sistemáticas são métodos que ajudam a
                    encontrar, filtrar e avaliar a qualidade de estudos
                    primários imersos em uma vasta literatura, enquanto, as
                    meta-análises são métodos estatísticos que permitem a
                    “reanálise” dos conjuntos dos dados extraídos dos estudos
                    primários formando um resultado único.
                  </figcaption>
                </p>

                As revisões sistemáticas, com ou sem meta-análise, também
                podem ser empregadas para criar o “estado-da-arte”, para
                identificar lacunas no conhecimento, bem como para levantar
                novas hipóteses sobre a efetividade e segurança de um fármaco
                ou sobre os mecanismos de ação dele.
                <StaticImage
                  className="my-3"
                  src="../../../assets/images/wikis/pt/sysphar/FIG_4.png"
                  alt="FIG_4"
                />
                <p>
                  <figcaption className="figure-caption">
                    Figura 4. Revisões Sistemáticas e Meta-análises em
                    Farmacologia Legenda: Figura adaptada de Bolzan e Lino de
                    Oliveira (2021).
                  </figcaption>
                </p>

                <p>
                  Referências sugeridas: {" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://www.sciencedirect.com/science/article/abs/pii/S0163725820300437"

                  >
                    Lino de Oliveira et al. (2020)
                  </a>
                  ;{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5568412/"

                  >
                    Hesen et al. (2017)
                  </a>
                  ;{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8268344/"

                  >
                    Al-Waeli et al. (2021)
                  </a>
                  . Veja revisões sistemáticas em farmacologia na aba{" "}
                  <Link to="/portfolio-e-iniciativas">
                    “Portfólio & Iniciativas”
                  </Link>
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#20">
                <h3 className="fw-bold">2 Plano geral das Revisões Sistemáticas e Meta-análises</h3>
                <h3>2.1 Planejamento, implementação, publicação e manutenção</h3>
                <p>
                  Aqui no SysPhar, o plano geral das revisões sistemáticas e
                  meta-análises – cujo fluxograma é apresentado na Figura 5 – é
                  dividido em fases sequencialmente organizadas: inicia com a
                  fase 0, antes de começar o processo; passa pelas fases de 1 a
                  5, nas quais a pergunta da pesquisa é formulada, o protocolo é
                  elaborado, registrado e implementado, e os resultados são
                  publicados; e termina na fase 6, em que se mantêm a revisão
                  sistemática e meta-análise vivas. É muito recomendável que
                  estas etapas sejam realizadas nesta ordem, para que as
                  decisões analíticas e metodológicas sejam todas tomadas antes
                  que as ações analíticas e metodológicas sejam realizadas.
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/sysphar/FIG_5.png"
                    alt="FIG_5"
                  />
                  <p>
                    <figcaption className="figure-caption">
                      Figura 5 – Plano geral para revisões sistemáticas e
                      meta-análises* Legenda: Figura adaptada de Bolzan e Lino de
                      Oliveira (2022).
                    </figcaption>
                  </p>
                  <br />
                  *Nota: nem toda revisão sistemática precisa ser seguida de uma
                  meta-análise e nem toda revisão sistemática e meta-análise
                  precisa se tornar viva. Cada farmacologista deve decidir de
                  acordo com as demandas do tema e objetivos de sua pesquisa.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#30">
                <h3 className="fw-bold">3 Fase 0: Antes de começar</h3>
                <h3>3.1 Relevância do estudo</h3>
                <p>
                  Revisões sistemáticas e meta-análises são trabalhosas, custam
                  tempo e recursos humanos. Por isso, sugerimos fortemente que
                  ANTES DE COMEÇAR, o farmacologista interessado em usar estas
                  abordagens faça uma análise prévia do contexto de uma revisão
                  sistemática e meta-análise em farmacologia no tema de seu
                  interesse, respondendo às seguintes perguntas:
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/sysphar/FIG_6.png"
                    alt="FIG_6"
                  />
                  <p>
                    <figcaption className="figure-caption">
                      Figura 6. Fase 0: Analise a relevância de se fazer esta
                      revisão em Farmacologia
                    </figcaption>
                  </p>
                </p>
                <p>
                  Se você respondeu “sim” às perguntas de 1 a 5 e “não” à
                  pergunta 6, é possível que sua revisão tenha relevância
                  científica e tecnológica e seja original. Portanto, prepare
                  sua equipe!
                </p>
                <p>
                  Caso você tenha respondido “sim” também à pergunta 6, ou seja,
                  se a revisão de seu interesse já estiver em andamento,
                  considere entrar em contato com a equipe dessa revisão para
                  uma possível colaboração.
                </p>
                <p>
                  *Nota: para verificar se o tema foi previamente revisado, faça
                  uma busca em bases bibliográficas relevantes para a
                  Farmacologia, como a Medline via Pubmed, Scopus, Web of
                  Science e Embase. Também considere usar plataformas de
                  pré-impressão, por exemplo a bioRxiv, medRxiv e OSF, ou usar
                  depósitos de revisões sistemáticas em andamento, como a
                  PROSPERO.{" "}
                </p>
                <h3>3.2 Preparando a equipe para começar</h3>
                <p>
                  Para o planejamento, implementação, publicação e manutenção de
                  uma revisão sistemática e uma meta-análise em Farmacologia, a
                  equipe ideal seria formada por:
                </p>
                <p>
                  <ul>
                    <li>
                      farmacologistas: responsáveis pela seleção do tema,
                      elaboração da pergunta da revisão relevante em
                      Farmacologia e elaboração do protocolo (ex., ajudando a
                      identificar os aspectos relevantes para o escopo da busca,
                      para a seleção dos estudos, e para a análise dos dados)
                    </li>
                    <li>
                      bibliotecários(as) ou especialistas em revisões
                      sistemáticas da literatura: responsáveis pela elaboração
                      do protocolo (ex., ajudando a criar as estratégias de
                      busca na literatura), recomendação de softwares,
                      ferramentas e abordagens de busca e seleção de literatura
                      relevante para a pergunta da revisão.
                    </li>
                    <li>
                      estatísticos(as) ou especialistas em meta-análise:
                      responsáveis pela elaboração do protocolo (ex., ajudando a
                      selecionar os tipos de cálculos de tamanho de efeito e
                      modelos estatísticos de acordo com os desfechos de
                      interesse) e recomendação de softwares, ferramentas e
                      abordagens para fazer a análise dos dados.
                    </li>
                    <li>
                      revisores(as): responsáveis pela realização das etapas
                      menos especializadas do processo (ex., seleção de estudos
                      aplicando critérios pré-estabelecidos, extração de dados
                      de acordo com o protocolo e tabulação dos dados extraídos
                      dos estudos). Mesmo revisores principiantes podem
                      contribuir nas etapas de implementação do protocolo de
                      revisão, sob a supervisão de membros mais experientes da
                      equipe. Estes revisores podem ser recrutados durante a
                      realização dos trabalhos.
                    </li>
                  </ul>
                </p>
                <p>
                  Pode ser útil criar papéis específicos para cada membro da
                  equipe. Cada papel pode ser desempenhado por mais de uma
                  pessoa e cada pessoa pode desempenhar mais de um papel
                  simultaneamente.
                </p>
                <p>
                  <ul>
                    <li>
                      Líder: responsável pelo gerenciamento das atividades da
                      equipe e dos documentos da revisão*.
                    </li>
                    <li>
                      Revisor independente 1: responsável por aplicar critérios
                      de elegibilidade dos estudos, extrair e tabular os dados
                      dos estudos incluídos na revisão de forma independente do
                      revisor 2.{" "}
                    </li>
                    <li>
                      Revisor independente 2: responsável por aplicar critérios
                      de elegibilidade dos estudos, extrair e tabular os dados
                      dos estudos incluídos na revisão de forma independente do
                      revisor 1.{" "}
                    </li>
                    <li>
                      Revisor 3 (conciliador): responsável por conciliar as
                      discrepâncias entre os revisores independentes 1 e 2.{" "}
                    </li>
                  </ul>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/sysphar/FIG_7.png"
                    alt="FIG_7"
                  />
                  <p>
                    <figcaption className="figure-caption">
                      Figura 7. Fase 0: Prepare uma equipe mínima
                    </figcaption>
                  </p>
                  <p>
                    *Nota: no início do processo de revisão, é importante
                    registrar e armazenar todos os documentos relacionados à
                    revisão em um local de compartilhamento (exemplo:
                    <a target="_blank" rel="noopener noreferrer"
                      href="https://osf.io/dashboard"


                    >
                      OSF
                    </a>
                    ,
                    <a target="_blank" rel="noopener noreferrer"
                      href="https://www.google.com/intl/pt-BR/drive/"


                    >
                      Google Drive
                    </a>
                    ,
                    <a target="_blank" rel="noopener noreferrer"
                      href="https://www.microsoft.com/pt-br/microsoft-365/onedrive/online-cloud-storage"


                    >
                      OneDrive
                    </a>
                    ), para que toda a equipe tenha acesso.
                  </p>
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#40">
                <h3 className="fw-bold">4 Fase 1: elaborando a pergunta da revisão</h3>
                <p>
                  A definição da questão ou pergunta da pesquisa guiará todas as
                  decisões nas fases subsequentes de uma revisão sistemática e
                  meta-análise. Frequentemente, as perguntas de pesquisa em
                  Farmacologia assumem estruturas semelhantes a estas:
                </p>
                <p>
                  <ul>
                    <li>
                      O fármaco X é eficaz na cura ou melhora de uma condição Y
                      em um tipo específico de experimento ou teste?”;
                    </li>
                    <li>
                      “Existe uma relação dose-resposta entre o fármaco X e a
                      resposta Y?”;
                    </li>
                    <li>
                      “Uma população (de ratos, camundongos, humanos,
                      preparações in vitro, etc.) é suscetível aos efeitos do
                      fármaco X?”;
                    </li>
                    <li>
                      Uma subpopulação da amostra (espécie, linhagem, sexo etc.)
                      é mais ou menos suscetível aos efeitos do fármaco X?”.
                    </li>
                  </ul>
                  Assim, ferramentas mnemônicas foram desenvolvidas para
                  auxiliar na elaboração de uma pergunta de pesquisa clara,
                  objetiva e que atenda os interesses da revisão.
                </p>
                <h3>4.1 Ferramenta PICO </h3>
                <StaticImage
                  className="my-3"
                  src="../../../assets/images/wikis/pt/sysphar/FIG_8.png"
                  alt="FIG_8"
                />
                <p>
                  <figcaption className="figure-caption">
                    Figura 8. Elaborando a pergunta de revisão
                  </figcaption>
                </p>
                <p>
                  A ferramenta PICO (P – Paciente ou população ou problema; I –
                  Intervenção; C – Comparação ou Controle; O – Outcome ou
                  Desfecho), desenvolvida para revisar ensaios clínicos
                  controlados e randomizados, é também adequada para revisões de
                  estudos não-clínicos em Farmacologia.{" "}
                </p>

                <StaticImage
                  className="my-3"
                  src="../../../assets/images/wikis/pt/sysphar/TAB_1.png"
                  alt="TAB_1"
                />
                <p>
                  <figcaption className="figure-caption">
                    Tabela 1. Ferramenta PICO: definições e exemplos em
                    Farmacologia
                    <br />
                    Fonte: adaptado de Bolzan e Lino de Oliveira (2022).
                  </figcaption>
                </p>
                <p>
                  Referências sugeridas: {" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1904193/"
                  >
                    Schardt et al., (2007)
                  </a>
                  ;{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6148624/"

                  >
                    Eriksen e Frandsen (2018).
                  </a>
                </p>
                <h3>4.2 Outras ferramentas mnemônicas </h3>
                <p>
                  Outras ferramentas mnemônicas podem ser úteis para fazer
                  perguntas em alguns tipos específicos de pesquisa in vitro, in
                  vivo, ex vivo em Farmacologia.
                </p>
                <p>
                  São exemplos de alternativas à ferramenta PICO: o PICOC (PICO
                  mais Contexto); PICOT (PICO mais Tempo); SPICE (S – Setting ou
                  contexto experimental; P – População; I – Intervenção; C –
                  Comparação; E – Evaluation ou Avaliação); e a SPIDER (S –
                  Sample ou Amostra de interesse; P – Phenomenon ou Fenômeno de
                  Interesse; D – Desenho Experimental; E – Evaluation ou
                  Avaliação; R – Research type ou Tipo de pesquisa).{" "}
                </p>
                <p>
                  Referências sugeridas: {" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://journals.sagepub.com/doi/10.1177/1049732312452938?url_ver=Z39.88-2003&amp;rfr_id=ori:rid:crossref.org&amp;rfr_dat=cr_pub%20%200pubmed"
                  >
                    Cooke, Smith e Booth (2012)
                  </a>
                  ;{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://parsif.al/help/about-the-picoc/"
                  >
                    Wohlin et al., (2012)
                  </a>
                  .
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#50">
                <h3 className="fw-bold">5 Fase 2: elaborando o protocolo de revisão</h3>
                <h3>5.1 Modelos de protocolos</h3>
                <p>
                  O protocolo é um documento que contém a descrição completa e
                  detalhada de todos os métodos selecionados para responder à
                  pergunta de pesquisa (que foi elaborada na primeira fase do
                  processo).
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/sysphar/FIG_9.png"
                    alt="FIG_9"
                  />
                  <p>
                    <figcaption className="figure-caption">
                      Figura 9. Fase 2: Protocolo de revisão
                    </figcaption>
                  </p>
                </p>
                <p>
                  Diversas organizações especializadas oferecem sugestões de
                  protocolos que seguem boas práticas para revisão sistemática e
                  meta-análise de estudos em geral (
                  <a target="_blank" rel="noopener noreferrer"

                    href="http://www.prisma-statement.org/Extensions/Protocols"
                  >
                    PRISMA
                  </a>
                  ), em humanos (
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://training.cochrane.org/handbook/current/chapter-01#section-1-5">
                   COCHRANE
                  </a>
                  ) ou animais (
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://camaradesbrasil.bio.br/portfolio-e-iniciativas"
                    >
                    CAMARADES 
                  </a>
                  , 
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://onlinelibrary.wiley.com/doi/epdf/10.1002/ebm2.7"
                  >
                    SYRCLE
                  </a>
                  ).{" "}
                </p>
                <p>
                  Sugerimos aqui o uso do formulário estruturado SYRCLE para
                  elaboração de um protocolo completo. O download do formulário
                  na versão .docx pode ser feito {" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/58ftv/">
                    AQUI
                  </a>.
                </p>
                <p>
                  Alguns exemplos de protocolos completos de revisão sistemática
                  e meta-análise em Farmacologia podem ser encontrados nas
                  seguintes referências: {" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42022289224">
                    <em> Ferreira et al., 2022</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="Disponível em https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42021221778">
                    <em> Martins et al., 2021</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://dx.doi.org/10.1136/bmjos-2017-000043">
                    <em> Ramos-Hryb et al., 2019 </em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020200604">
                    <em> Martins et al., 2020</em>
                  </a>
                </p>
                <h3>5.2 Planejando a estratégia de busca</h3>
                <p>
                  O protocolo deve conter um plano detalhado sobre como as
                  publicações relevantes serão obtidas nas bases bibliográficas.
                  Este plano é denominado “estratégia de busca”. *
                </p>
                <p>
                  Uma estratégia de busca consiste na descrição das listas de
                  termos de busca relevantes, também conhecidos como
                  palavras-chave. Estes termos são combinados por operadores
                  booleanos (ex., AND, OR, NOT) e por sintaxes usadas nas
                  pesquisas realizadas em diferentes bancos de dados
                  bibliográficos.{" "}
                </p>
                <p>
                  Veja exemplos de estratégia de busca usando termos
                  relacionados a População (P), Intervenção (I) e Desfecho (O)
                  de interesse ou a combinação deles.
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/sysphar/FIG_10.png"
                    alt="FIG_10"
                  />
                  <p>
                    <figcaption className="figure-caption">
                      Figura 10. Exemplo de estratégia de busca no Pubmed
                    </figcaption>
                  </p>
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/sysphar/FIG_11.png"
                    alt="FIG_11"
                  />
                  <p>
                    <figcaption className="figure-caption">
                      Figura 11. Exemplo de estratégia de busca na base
                      bibliográfica Scopus
                    </figcaption>
                  </p>
                </p>
                Veja também exemplos de protocolos apresentando estratégias de
                busca em Farmacologia (
                <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42019125868">
                  <em>Zameer et al., 2019</em>
                </a>
                <em>; </em>
                <a target="_blank" rel="noopener noreferrer" href="https://onlinelibrary.wiley.com/action/downloadSupplement?doi=10.5694%2Fmja2.50992&amp;file=mja250992-sup-0001-Supinfo.pdf">
                  <em>Abdel</em>
                  <em> et al., 2021</em>
                </a>
                <em>; </em>
                <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42018109097">
                  <em>Ma et al., 2018</em>
                </a>
                <em>; </em>
                <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020184547">
                  <em>Nibber et al., 2020</em>
                </a>
                <em>).</em>.
                <ol>
                  <li>
                    buscas rápidas, preliminares nas bases bibliográficas,
                    chamadas{" "}
                    <a target="_blank" rel="noopener noreferrer" href="https://osf.io/p9uvk/">
                      estudos pilotos
                    </a>
                    , ajudam a estabelecer a estratégia de busca final, a ser
                    apresentada no protocolo;
                  </li>
                  <li>
                    os diferentes mecanismos de busca operam de forma diferente.
                    Informe-se sobre estes mecanismos para usá-los de forma
                    adequada;
                  </li>
                  <li>
                    em virtude destas diferenças, pesquisas combinando
                    diferentes bases bibliográficas fornecerão uma revisão mais
                    abrangente;
                  </li>
                  <li>
                    o acesso a algumas bases bibliográficas requer pagamento ou
                    login institucional.
                  </li>
                </ol>
                <h3>5.3 Planejando a estratégia de seleção </h3>
                <p>
                  Neste item, os autores do protocolo deverão descrever como os
                  estudos relevantes serão selecionados entre as publicações
                  recuperadas a partir das bases bibliográficas.{" "}
                </p>
                <p>
                  A estratégia de seleção inclui, por exemplo, o planejamento de
                  fases de triagem (pela leitura do título, resumo ou texto
                  completo) e o número de revisores independentes e
                  conciliadores envolvidos. Decisões adicionais podem incluir,
                  por exemplo, entrar em contato com os autores correspondentes
                  no caso de ausência de informações.
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/sysphar/FIG_12.png"
                    alt="FIG_12"
                  />
                  <p>
                    <figcaption className="figure-caption">
                      Figura 12. Seleção de estudos relevantes para a revisão
                    </figcaption>
                  </p>
                </p>
                <p>
                  Veja exemplos de protocolos apresentando estratégias de
                  seleção no campo da farmacologia nestas referências: {" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42019123012">
                    <em>Ballard et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42019125868">
                    <em>Zameer et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42019124404">
                    <em>Pettorruso et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42019116363">
                    <em>David et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020210067">
                    <em>Sartim et al., 2020</em>
                  </a>
                </p>
                <h3>5.4 Planejando a extração de dados </h3>
                <p>
                  Neste item, os autores do protocolo deverão descrever as
                  abordagens que serão adotadas para extração de dados
                  qualitativos e quantitativos dos estudos incluídos na revisão.
                  Idealmente, essa etapa será realizada por dois revisores
                  independentes, para evitar erros como, por exemplo, a extração
                  de dados incorretos. O protocolo também deve fornecer uma
                  análise de confiabilidade inter-revisores (usando, por
                  exemplo, o índice Kappa de Cohen).{" "}
                </p>
                <p>
                  Os dados qualitativos são extraídos para avaliação da validade
                  externa (grau de generalização dos resultados) e validade
                  interna (risco de viés experimental) dos estudos incluídos na
                  revisão.
                </p>
                <p>
                  Para avaliação da validade externa, são extraídas as
                  informações relativas a características dos estudos definidas
                  no momento da elaboração da pergunta usando a ferramenta
                  mnemônica. Por exemplo, se a ferramenta mnemônica aplicada foi
                  a PICO, é possível que você queira extrair e tabular
                  informações sobre a população estudada (ex., espécie, idade e
                  sexo), intervenção farmacológica (ex., tipo de composto,
                  classe do fármaco, a dose, via de administração, duração do
                  tratamento), comparação ou controle (ex., tipo de composto, a
                  via de administração, duração do tratamento) e/ou sobre os
                  desfechos (ex., método de obtenção, tipo de métrica, unidade
                  de medida, análise primária, etc.).{" "}
                </p>
                <p>
                  Para avaliação da validade interna, são extraídas as
                  informações requeridas por ferramentas específicas para
                  avaliação do risco de viés. Na Farmacologia, sugerimos o uso
                  das seguintes ferramentas:
                  <ul>
                    <li>
                      <a target="_blank" rel="noopener noreferrer"

                        href="https://mfr.osf.io/render?url=https://osf.io/9mzr2/?direct%26mode=render%26action=download%26mode=render"
                      >
                        RoB-SYRCLE
                      </a>
                      , adaptada para avaliação de estudos com experimentação
                      animal a partir da ferramenta RoB da Cochrane. No link{" "}
                      <Link to="/ferramentas-e-recursos">
                        Ferramentas & Recursos
                      </Link>{" "}
                      disponibilizamos um guia rápido para o seu uso.{" "}
                    </li>
                    <li>
                      <a target="_blank" rel="noopener noreferrer"

                        href="https://amstar.ca/Amstar-2.php"
                      >
                        AMSTAR 2
                      </a>
                      , aplicável para avaliação da qualidade interna das
                      revisões sistemáticas de estudos randomizados ou não
                      randomizados de intervenções em saúde.{" "}
                    </li>
                  </ul>
                </p>
                <p>
                  Os dados numéricos (ex., médias, desvios-padrão, tamanhos
                  amostrais, número de comparações, valores de p, F ou t,
                  índices de correlação ou qualquer outra medida, manipulação ou
                  transformação dos dados) são necessários para os cálculos de
                  tamanhos de efeito de cada estudo primário e para a condução
                  da meta-análise.{" "}
                </p>
                <p>
                  Veja exemplos para avaliação de planejamento da qualidade
                  interna nestas referências: (
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42018109097">
                    <em>Ma et al., 2018</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42019120418">
                    <em>Souza et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42021260343">
                    <em>Carabali et al., 2021</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://doi.org/10.1186/s13643-016-0374-6"
                  >
                    <em>Williamson et al., 2016</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020179825">
                    <em>Azab et al., 2020</em>
                  </a>
                  ),{" "}
                </p>
                <p>
                  Exemplos de avaliação da qualidade externa de estudos em
                  farmacologia podem ser encontrados em:{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020203826">
                    <em>Lino de Oliveira et al., 2020</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020179825">
                    <em>Azab et al., 2020</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020210067">
                    <em>Sartim et al., 2020</em>
                  </a>
                  .
                </p>
                <h3>5.5 Planejando a meta-análise </h3>
                <p>
                  Neste item, os autores do protocolo deverão descrever os
                  planos para calcular o tamanho de efeito de cada estudo
                  individual e da meta-análise.{" "}
                </p>
                <h4>5.5.1 Cálculos de tamanho de efeito</h4>
                <p>
                  Os protocolos devem explicar como os tamanhos de efeito serão
                  calculados (ex., diferença média, razão de chances),
                  utilizando dados numéricos extraídos de estudos primários.
                  Além disso, os indicadores da incerteza do tamanho do efeito
                  devem ser mencionados. Geralmente, tamanhos de efeito são
                  apresentados com seus intervalos de confiança.
                </p>
                <p>
                  Tamanhos de efeito são estatísticas usadas para estimar a
                  diferença entre os valores de desfecho (ex., o resultado ou
                  variável dependente) em grupos experimentais (ex., entre o
                  grupo controle e o grupo tratado) ou a força da relação entre
                  um desfecho (ex., resultado ou variável dependente) e uma
                  intervenção (ex., tratamento com um fármaco).
                </p>
                <p>
                  Além do tipo de relação entre variáveis, o tipo de desfecho de
                  interesse (dicotômico ou contínuo, por exemplo) também afeta a
                  escolha do cálculo de tamanho de efeito, a decisão sobre como
                  os tamanhos de efeito estimados nos estudos primários serão
                  combinados e quais serão os indicadores da incerteza do
                  tamanho do efeito combinado (intervalos de confiança, erro
                  padrão, desvio padrão, heterogeneidade).
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/sysphar/TAB_2.jpeg"
                    alt="TAB_2."
                  />
                  <p>
                    <figcaption className="figure-caption">
                      Exemplos de Cálculos dos Tamanhos de Efeito em Diferenças
                      entre Médias de Grupos Independentes
                      <br />
                      Legenda: Tabela adaptada de{" "}
                      <a target="_blank" rel="noopener noreferrer"

                        href="https://www.researchgate.net/profile/Helena-Espirito-Santo/publication/273143169_Calcular_e_apresentar_tamanhos_do_efeito_em_trabalhos_cientificos_1_As_limitacoes_do_p_005_na_analise_de_diferencas_de_medias_de_dois_grupos_Calculating_and_reporting_effect_sizes_on_scientific_papers/links/54fa10290cf2040df21b1b1c/Calcular-e-apresentar-tamanhos-do-efeito-em-trabalhos-cientificos-1-As-limitacoes-do-p-0-05-na-analise-de-diferencas-de-medias-de-dois-grupos-Calculating-and-reporting-effect-sizes-on-scientific-pap.pdf"

                      >
                        Espírito-Santo e Daniel (2015)
                      </a>
                      . M = média de cada grupo; DP = Desvio Padrão de cada grupo;
                      n = número de sujeitos; gl = graus de liberdade (n -1). A
                      tabela é baseada nas seguintes referências:{" "}
                      <a target="_blank" rel="noopener noreferrer"

                        href="https://pubmed.ncbi.nlm.nih.gov/19565683/"

                      >
                        Cohen (1992) 
                      </a>
                      ;
                      <a target="_blank" rel="noopener noreferrer"

                        href="https://psycnet.apa.org/record/2009-05060-012"

                      >
                        Borenstein (2009) 
                      </a>
                      ;
                      <a target="_blank" rel="noopener noreferrer"

                        href="https://psycnet.apa.org/record/2011-21220-000"

                      >
                        Cummings (2012) 
                      </a>
                      ;
                      <a target="_blank" rel="noopener noreferrer"

                        href="https://psycnet.apa.org/record/1982-00195-001"

                      >
                        Hedges (1981) 
                      </a>
                      ;
                      <a target="_blank" rel="noopener noreferrer"

                        href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3840331/"

                      >
                        Lakens (2013) 
                      </a>{" "}
                      e{" "}
                      <a target="_blank" rel="noopener noreferrer"

                        href="https://books.google.com.br/books?hl=pt-BR&amp;lr=&amp;id=p-aFAwAAQBAJ&amp;oi=fnd&amp;pg=PA231&amp;dq=Parametric+measures+of+effect+size&amp;ots=TWyMQceHVF&amp;sig=9F7Itp3X0DWMlllCr4zSgErD_OY#v=onepage&amp;q=Parametric%20measures%20of%20effect%20size&amp;f=false"

                      >
                        Rosenthal (1994)
                      </a>
                      .
                    </figcaption>
                  </p>
                </p>
                <p>
                  No contexto da Farmacologia, o tamanho do efeito poderia ser
                  interpretado como o tamanho do efeito do tratamento com o
                  fármaco sobre a variável estudada.
                </p>
                <p>
                  Num experimento hipotético, farmacologistas poderiam testar a
                  hipótese que a influência do fármaco X sobre uma determinada
                  característica Y de uma amostra de interesse P é maior que a
                  influência do tratamento com o controle C nesta mesma
                  população (“em comparação ao controle C, qual o efeito do
                  fármaco X sobre a medida Y tomada na população P?”).
                </p>
                <p>
                  {" "}
                  Neste caso, o cálculo da diferença entre a médias de Y obtidas
                  na amostra P tratada com C ou X, indicaria o tamanho da
                  diferença entre os tratamentos.
                </p>
                <p>
                  Veja exemplos de protocolos de planejamento de tamanhos de
                  efeitos de estudos no campo da Farmacologia em
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020203826">
                    <em> Lino de Oliveira et al., 2020</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42021260343">
                    <em>Carabali et al., 2021</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020210067">
                    <em>Sartim et al., 2020</em>
                  </a>
                </p>
                <h4>5.5.2 Meta-análise</h4>
                <p>
                  Nesta etapa, os autores do protocolo deverão descrever, para
                  cada desfecho de interesse, se uma meta-análise será conduzida
                  ou não, e como esta decisão será tomada. Além disso, o
                  protocolo deve conter a descrição de como os estudos serão
                  agrupados para calcular o “tamanho do efeito combinado”, qual
                  é o modelo estatístico adequado para combinar os tamanhos de
                  efeito dos estudos primários, quais são os indicadores da
                  incerteza que serão calculados, e como a presença e tamanho de
                  um possível viés de publicação será investigado.
                </p>
                <ul>
                  <li>
                    Meta-análise: sim ou não? O número de estudos disponíveis
                    para o cálculo e que sejam, ao mesmo tempo, semelhantes
                    entre si, é um fator limitante para a meta-análise.
                    Teoricamente, dois estudos semelhantes são suficientes para
                    calcular um tamanho de efeito combinado. Contudo, como
                    acontece com outros métodos estatísticos, uma meta-análise
                    pode fornecer resultados inconclusivos com pequenos tamanhos
                    amostrais. Portanto, cálculos de tamanho amostral ou análise
                    de poder estatístico podem ser feitos, e o protocolo deve
                    conter a descrição destes cálculos.
                  </li>
                  <li>
                    Como os estudos serão agrupados para calcular o tamanho do
                    efeito combinado? Cabe aos autores definir, de acordo com a
                    área de pesquisa, quão semelhantes deverão ser os estudos
                    para compor um grupo ou subgrupo de resultados que serão
                    combinados. Exemplos: uma meta-análise será feita com todos
                    os estudos com o mesmo tipo de população (ou intervenção);
                    meta-análises estratificadas serão feitas com estudos
                    agrupados ou categorizados em subgrupos de
                    espécie/linhagem/sexo/idade dos animais experimentais ou de
                    tipos e doses dos fármacos ou de métodos da obtenção do
                    desfecho, etc.
                    <StaticImage
                      className="my-3"
                      src="../../../assets/images/wikis/pt/sysphar/FIG_13.png"
                      alt="FIG_13"
                    />
                    <p>
                      <figcaption className="figure-caption">
                        Figura 13. Exemplos de gráficos de floresta feitos a
                        partir de uma meta-análise usando estudos estratificados
                        em subgrupos de população (sexo ou linhagem)
                      </figcaption>
                    </p>
                    <br />
                    <StaticImage
                      className="my-3"
                      src="../../../assets/images/wikis/pt/sysphar/FIG_14.png"
                      alt="FIG_14"
                    />
                    <p>
                      <figcaption className="figure-caption">
                        Figura 14. Exemplos de gráficos de floresta feitos a
                        partir de uma meta-análise usando estudos estratificados
                        em subgrupos de intervenção (classe farmacológica ou
                        fármacos)
                      </figcaption>
                    </p>
                  </li>
                  <li>
                    Modelo estatístico da meta-análise: modelo fixo ou modelos
                    aleatórios? Os estudos primários em Farmacologia, mesmo
                    quando investigam uma mesma hipótese, variam em termos de
                    desenho experimental e nas formas pelas quais os
                    experimentos são conduzidos. Por causa desta
                    heterogeneidade, os modelos de efeitos aleatórios são
                    frequentemente elegíveis para a meta-análise em campos de
                    pesquisa como a Farmacologia.{" "}
                  </li>
                  <li>
                    Quais indicadores de incerteza das estimativas de tamanho de
                    efeito combinado serão usados? Em geral, cada tamanho de
                    efeito combinado estimado em uma meta-análise ou
                    meta-análise estratificada é acompanhado de intervalos de
                    confiança, erro padrão, desvio padrão e heterogeneidade
                    (ex., estatísticas Q, I²).
                  </li>
                  <li>
                    Como um possível viés de publicação será investigado? O viés
                    de publicação, quando planejado, pode ser avaliado
                    utilizando-se o método de plotagem de funil e trim-and-fill.
                    <StaticImage
                      className="my-3"
                      src="../../../assets/images/wikis/pt/sysphar/FIG_15.png"
                      alt="FIG_15"
                    />
                    <p>
                      <figcaption className="figure-caption">
                        Figura 15. Exemplo de gráfico de funil
                      </figcaption>
                    </p>
                  </li>
                </ul>
                <p>
                  Veja exemplos de protocolos descrevendo planos para a
                  meta-análise na Farmacologia (Yamato et al., 2014; Soliman et
                  al., 2019; Lino de Oliveira et al., 2020; Carabali et al.,
                  2021; Sartim et al., 2020).
                </p>
                <p>
                  Referências sugeridas: {" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://training.cochrane.org/handbook/current/chapter-01"

                  >
                    Cochrane Handbook
                  </a>
                  ;{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://www.bmj.com/content/343/bmj.d4002"

                  >
                    Sterne et al., (2011)
                  </a>
                  ;{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://pubmed.ncbi.nlm.nih.gov/24099992/"

                  >
                    Vesterinen et al., (2014)
                  </a>
                  ;{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://onlinelibrary.wiley.com/doi/book/10.1002/9780470743386"

                  >
                    Borenstein et al., (2009)
                  </a>
                  ;{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://pubmed.ncbi.nlm.nih.gov/31169736/"

                  >
                    Shi e Lin, (2019)
                  </a>
                  .
                </p>
                <h4>5.6 Planejando a publicação dos resultados </h4>
                <p>
                  Revisões sistemáticas geralmente envolvem diferentes
                  colaboradores e grandes grupos de revisores. Cabe aos autores
                  do protocolo especificar como cada um dos contribuidores
                  receberá o crédito devido e justo por seu aporte, para
                  acomodar as expectativas de todos os envolvidos. Também cabe a
                  eles identificar os meios de comunicação adequados para
                  disponibilizar os resultados ao público.
                </p>
                <p>
                  Veja exemplos de protocolos descrevendo planos de disseminação
                  no campo da farmacologia em (
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://doi.org/10.1136/bmjopen-2018-025145"
                  >
                    <em>Kavanagh et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://doi.org/10.1097/MD.0000000000017279"
                  >
                    <em>Lee et al., 2019</em>
                  </a>
                  ){" "}
                </p>
                <h4>5.7 Planejando a revisão sistemática viva </h4>
                <p>
                  Finalmente, os farmacologistas poderão manter suas revisões
                  sistemáticas e meta-análises vivas.{" "}
                </p>
                <p>
                  A{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://community.cochrane.org/review-production/production-resources/living-systematic-reviews">
                    Colaboração Cochrane
                  </a>{" "}
                  disponibiliza orientações para o delineamento dos métodos,
                  produção e publicação de revisões sistemáticas vivas nas áreas
                  clínicas, no{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.cochranelibrary.com/cdsr/reviews"
                  >
                    Cochrane Database of Systematic Reviews
                  </a>
                  .
                </p>
                <ul>
                  <li>
                    A{" "}
                    <a target="_blank" rel="noopener noreferrer"
                      href={siteMetadata.siteUrl}


                    >
                      CAMARADES BR
                    </a>{" "}
                    oferece aqui uma proposta de formulário, adaptado da
                    Colaboração Cochrane, para revisões sistemáticas e
                    meta-análises vivas em Farmacologia. Faça o download {" "}
                    <a target="_blank" rel="noopener noreferrer" href="https://osf.io/2xfh4/"  >AQUI
                    </a>.
                  </li>
                </ul>
              </Tab.Pane>
              <Tab.Pane eventKey="#60">
                <h3 className="fw-bold">6 Fase 3: registrando o protocolo de revisão</h3>
                <StaticImage
                  className="my-3"
                  src="../../../assets/images/wikis/pt/sysphar/FIG_16.png"
                  alt="FIG_16"
                />
                <p>
                  <figcaption className="figure-caption">
                    Figura 16. Fase 3: Registro do Protocolo
                  </figcaption>
                </p>
                <h3>6.1 Plataformas públicas de registro</h3>
                <p>
                  O registro do protocolo, feito antes de realizar a revisão
                  sistemática e meta-Análise, pode ajudar os revisores a
                  aderirem ao plano e reduz a incidência de revisões
                  tendenciosas, uma vez que uma longa lista de decisões precisa
                  ser tomada sobre o processo. Pode ser necessário voltar ao
                  protocolo em qualquer estágio da revisão, para encontrar
                  informações necessárias para implementação das suas
                  atividades.{" "}
                </p>
                <p>
                  Farmacologistas podem registrar suas revisões em plataformas
                  públicas, como:
                </p>
                <p>
                  <ul>
                    <li>
                      <a target="_blank" rel="noopener noreferrer"

                        href="https://www.crd.york.ac.uk/prospero/"
                      >
                        PROSPERO
                      </a>
                      : plataforma especializada em registros de protocolos para
                      Revisões Sistemáticas e Meta-Análises de estudos
                      relevantes para a saúde humana em humanos ou animais de
                      laboratório;{" "}
                    </li>
                    <li>
                      <a target="_blank" rel="noopener noreferrer" href="https://osf.io/">
                        Open Science Framework
                      </a>
                      : plataforma pública e gratuita usada para depositar
                      protocolos científicos de qualquer estudo, incluindo
                      protocolos de revisão sistemática e meta-análise.{" "}
                    </li>
                  </ul>
                </p>
                <p>
                  NOTA: os registros também podem ser feitos em revistas
                  científicas de revisão por pares. Veja exemplos em{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://doi.org/2010.1097/PR9.0000000000000766"
                  >
                    <em>Soliman et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://doi.org/10.1097/MD.0000000000017279"
                  >
                    <em>Lee et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a href="http://bmjopen.bmj.com/content/9/4/e025145" target="_blank" rel="noopener noreferrer">
                    <em>Kavanagh et al., 2019</em>
                  </a>
                  <em>;</em>
                  <a target="_blank" rel="noopener noreferrer"
                    href="https://ri.conicet.gov.ar/handle/11336/103342"
                  >
                    <em>Ramos-Hryb et al., 2019</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.crd.york.ac.uk/prospero/display_record.php?ID=CRD42020191711">
                    <em>Ang et al., 2020</em>
                  </a>
                  <em>; </em>
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://pubmed.ncbi.nlm.nih.gov/32292826/"
                  >
                    Maguire and Guérin, 2020
                  </a>
                  ,{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/96csg/">
                    <em>Thombs et al., 2020</em>
                  </a>{" "}
                  <em>, </em>
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8647582/"
                  >
                    <em>Bolzan e Lino de Oliveira, 2021</em>
                  </a>
                  .
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#70">
                <h3 className="fw-bold">Fase 4: implementação do protocolo de revisão</h3>
                <p>
                  A implementação do protocolo de revisão (fase 4) consiste em
                  colocar em prática as ações previstas no protocolo previamente
                  elaborado (fase 2) e registrado (fase 3).
                  <StaticImage
                    className="my-3"
                    src="../../../assets/images/wikis/pt/sysphar/FIG_17.png"
                    alt="FIG_17"
                  />
                  <p>
                    <figcaption className="figure-caption">
                      Figura 17. Fase 4: implementação do protocolo da revisão
                    </figcaption>
                  </p>
                </p>
                <p>
                  Ao longo do processo de implementação, os revisores precisam
                  fazer anotações completas sobre todas as decisões tomadas
                  nesta fase, incluindo os eventuais desvios em relação ao
                  descrito no protocolo.{" "}
                </p>
                <p>
                  Estas anotações aumentam a transparência e ajudam a organizar
                  o trabalho da equipe. Além disso, ajudam os leitores a julgar
                  a qualidade da revisão.
                </p>

                <h3>7.1 Softwares, scripts e outros recursos gratuitos</h3>
                <p>
                  Identificamos diversos recursos, gratuitos ou comerciais,
                  disponíveis para implementar os processos de uma revisão
                  sistemática e meta-análise e que podem beneficiar revisões em
                  temas farmacológicos. Os níveis de automatização destas
                  ferramentas variam, mas, no geral, aceleram as atividades
                  necessárias para a revisão.{" "}
                </p>
                <p>
                  Veja abaixo exemplos de ferramentas úteis e, em sua maioria,
                  gratuitas.
                </p>

                <h4>7.1.1 Busca em bases bibliográficas</h4>
                <p>
                  O menu de "pesquisa avançada" dos buscadores de bases
                  bibliográficas virtuais (por exemplo, Medline via{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://pubmed.ncbi.nlm.nih.gov/"
                  >
                    Pubmed
                  </a>
                  ,{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.scopus.com/home.uri"
                  >
                    Scopus, 
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://access.clarivate.com/login?app=wos&amp;alternative=true&amp;shibShireURL=https:%2F%2Fwww.webofknowledge.com%2F%3Fauth%3DShibboleth&amp;shibReturnURL=https:%2F%2Fwww.webofknowledge.com%2F%3Fmode%3DNextgen%26action%3Dtransfer%26path%3D%252Fwos%252Fwoscc%252Fbasic-search%26DestApp%3DUA&amp;referrer=mode%3DNextgen%26path%3D%252Fwos%252Fwoscc%252Fbasic-search%26DestApp%3DUA%26action%3Dtransfer&amp;roaming=true">
                    Web of Science 
                  </a>
                  ,{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.embase.com/?phase=continueToApp#search"
                  >
                    Embase
                  </a>
                  ) costuma ser mais apropriado para o tipo de pesquisa
                  necessária em uma revisão sistemática do que o menu de busca
                  simples.
                </p>
                <p>
                  Recuperar documentos de diferentes bases virtuais requer
                  auxílio de um software de gerenciamento de referência para
                  deduplicação. O{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.mendeley.com/download-reference-manager/"
                  >
                    Mendeley
                  </a>
                  ,{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://www.zotero.org/">
                    Zotero
                  </a>
                  , e{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://www.rayyan.ai/">
                    Rayyan
                  </a>{" "}
                  são exemplos de gerenciadores grátis. A CAMARADES desenvolveu
                  um aplicativo web para Gdeduplicação{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://www.ed.ac.uk/clinical-brain-sciences/research/camarades/tools-resources">
                    Automated Systematic Search Deduplication Tool (ASySD).
                  </a>
                </p>
                <p>
                  O número de publicações obtidas nas pesquisas realizadas em
                  cada uma das bases bibliográficas, antes e depois da
                  deduplicação, deve ser anotado em um fluxograma PRISMA que
                  será apresentado no relatório final da revisão. O fluxograma PRISMA pode ser baixado 
                  <a target="_blank" rel="noopener noreferrer"

                    href="http://prisma-statement.org/prismastatement/flowdiagram.aspx"
                  >
                    AQUI
                  </a>
                  .
                </p>
                <StaticImage
                  className="my-3"
                  src="../../../assets/images/wikis/pt/sysphar/FIG_18.png"
                  alt="FIG_18"
                />
                <p>
                  <figcaption className="figure-caption">
                    Figura 18. Exemplo de um fluxograma PRISMA preenchido
                  </figcaption>
                </p>

                <h4>7.1.2 Triagem de estudos relevantes</h4>
                <p>
                  O software de gerenciamento de referência também é útil para
                  aplicar os critérios de seleção e elegibilidade de estudos
                  relevantes. Por exemplo: Mendeley e Rayyan podem ser usados
                  para classificar semi-automaticamente as referências em uma
                  biblioteca.{" "}
                </p>
                <p>
                  Outros recursos gratuitos são{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://asreview.nl/">
                    ASReview
                  </a>
                  ,{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://parsif.al/">
                    Parsifal
                  </a>
                  ,{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://revtools.net/">
                    Rvtools
                  </a>{" "}
                  (ferramentas para síntese de evidências em R),{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://sysrev.com/">
                    Sysrev
                  </a>
                  , Screenatron e Systematic Review Accelerator e{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://syrf.org.uk/">
                    SyRF
                  </a>{" "}
                  (CAMARADES).
                </p>
                <p>
                  Os resultados dos processos de triagem (número de estudos
                  excluídos, motivos de exclusão, número de estudos incluídos)
                  devem ser anotados em um fluxograma PRISMA que será
                  apresentado no relatório final da revisão. O fluxograma PRISMA
                  pode ser baixado{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="http://prisma-statement.org/prismastatement/flowdiagram.aspx"
                  >
                    AQUI
                  </a>
                  .
                </p>

                <h4>7.1.3 Extração de dados </h4>
                <p>
                  Gerenciadores de referências (
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.mendeley.com/download-reference-manager/"
                  >
                    Mendeley
                  </a>
                  ,{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://www.zotero.org/">
                    Zotero
                  </a>{" "}
                  e{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://www.rayyan.ai/">
                    Rayyan
                  </a>
                  ) podem ser úteis para obter informações bibliográficas dos
                  estudos incluídos (por exemplo, nome dos autores, ano,
                  revista).{" "}
                </p>
                <p>
                  Ferramentas online, como{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://colandrcommunity.com/"
                  >
                    Colandr
                  </a>{" "}
                  e o{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://syrf.org.uk/">
                    SyRF
                  </a>
                  , ajudam a anotar, tabular, compartilhar e gerenciar dados
                  qualitativos e numéricos extraídos das publicações de forma
                  eficiente. Existem versões gratuitas de ferramentas como a{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://markummitchell.github.io/engauge-digitizer/"
                  >
                    Engauge Digitizer
                  </a>{" "}
                  ou{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://automeris.io/WebPlotDigitizer/"
                  >
                    WebPlotDigitizer
                  </a>{" "}
                  que convertem imagens de gráficos ou mapas em números.
                  Medições manuais de elementos de gráficos (escala ou tamanhos
                  de barras ou extensões de linhas e outros atributos) são
                  possíveis usando a régua digital de leitores de PDF (por
                  exemplo, versão gratuita do Adobe Acrobat Reader DC).
                </p>
                <StaticImage
                  className="my-3"
                  src="../../../assets/images/wikis/pt/sysphar/FIG_19.png"
                  alt="FIG_19"
                />
                <p>
                  <figcaption className="figure-caption">
                    Figura 19. Extração de dados dos estudos incluídos usando a
                    régua digital
                  </figcaption>
                </p>
                <p>
                  Algum conhecimento de linguagem de programação permite usar o
                  pacote de{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://cran.r-project.org/web/packages/metaDigitise/index.html"
                  >
                    metaDigitise R
                  </a>{" "}
                  e 
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://cran.r-project.org/web/packages/metagear/metagear.pdf"
                  >
                    metagear
                  </a>
                  para extrair estatísticas descritivas. Tabelas em formato .csv
                  podem ser formatadas para uso em futuras meta-análises (
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/rmqe3/">
                    Tabela 1
                  </a>
                  ).
                </p>
                <p>
                  O pacote{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://cran.r-project.org/web/packages/pacman/index.html"
                  >
                    pacman
                  </a>{" "}
                  é uma ferramenta de gerenciamento de pacotes R, que combina
                  funcionalidades{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.rdocumentation.org/packages/dplyr/versions/0.7.8"
                  >
                    dplyr
                  </a>
                  ,{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://cran.r-project.org/web/packages/irr/irr.pdf"
                  >
                    irr,
                  </a>{" "}
                  e{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://cran.r-project.org/web/packages/rel/index.html"
                  >
                    rel
                  </a>
                  , usados para cálculos de concordâncias entre revisores ({" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3900052/"
                  >
                    Cohen Kappa
                  </a>
                  ), sempre que dois revisores independentes realizarem uma
                  atividade. Disponibilizamos aqui no SysPhar modelos de tabela
                  .csv para análises de concordâncias entre revisores (
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/k7yng/">
                    Tabela 2
                  </a>
                  ) além de scripts para o uso da ferramenta{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/z93qr/">
                    Pacman
                  </a>
                  .
                </p>

                <h4>
                  7.1.4 Avaliação do risco de viés usando a ferramenta RoB-Syrcle
                </h4>
                <p>
                  A apresentação dos dados da ferramenta RoB-Syrcle pode ser
                  facilitada por ferramenta robvis (veja{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://onlinelibrary.wiley.com/doi/10.1002/jrsm.1411"
                  >
                    McGuinness and Higgins, 2020
                  </a>
                  ). Disponibilizamos aqui no SysPhar modelos de tabela .csv
                  para avaliação do risco de viés (
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/ruscj/">
                    Tabela 3
                  </a>
                  ) e de scripts para o uso da ferramenta{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/ztmyr/">
                    Robvis
                  </a>
                  .
                </p>
                <StaticImage
                  className="my-3"
                  src="../../../assets/images/wikis/pt/sysphar/FIG_20.png"
                  alt="FIG_20"
                />
                <p>
                  <figcaption className="figure-caption">
                    Figura 20. Exemplo de apresentação dos resultados da
                    avaliação do risco de viés usando a ferramenta RoB – Syrcle
                  </figcaption>
                </p>

                <h4>7.1.5 Cálculo de meta-análise</h4>
                <p>
                  Ferramentas como Meta-
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://www.erim.eur.nl/research-support/meta-essentials/"
                  >
                    Meta-essentials
                  </a>
                  ,
                  <a target="_blank" rel="noopener noreferrer" href="https://training.cochrane.org/online-learning/core-software-cochrane-reviews/revman">
                    RevMan
                  </a>
                  ou{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="http://www.cebm.brown.edu/openmee/"
                  >
                    OpenMEE
                  </a>{" "}
                  podem ser usadas gratuitamente na condução da meta-análise por
                  revisores não familiarizados com softwares estatísticos ou
                  linguagens de programação.
                </p>
                <p>
                  Os revisores familiarizados com a codificação podem se
                  beneficiar de softwares como pacotes e scripts{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://cran.r-project.org/web/packages/meta/index.html"
                  >
                    Meta
                  </a>{" "}
                  ou {" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://cran.r-project.org/web/packages/metafor/index.html"
                  >
                    Metafor
                  </a>{" "}
                  em R, Python ou{" "}
                  <a target="_blank" rel="noopener noreferrer"
                    href="http://www.cebm.brown.edu/openmeta/download.html"
                  >
                    OpenMeta [Analista]
                  </a>
                  . O pacote{" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="https://cran.r-project.org/web/packages/metagear/index.html"
                  >
                    metagear
                  </a>{" "}
                  para o R é um recurso gratuito que, além de facilitar a
                  triagem e a extração de dados, também é usado para
                  meta-análise.{" "}
                </p>
                <p>
                  Disponibilizamos aqui no SysPhar modelos de scripts para o {" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/r79jw/">
                    metafor
                  </a>{" "}
                  (meta-análise, viés de publicação) além do{" "}
                  <a target="_blank" rel="noopener noreferrer" href="https://osf.io/759jm/">
                    metapower
                  </a>
                  {" "}
                  para cálculo do poder estatístico, se aplicável.
                </p>
                <StaticImage
                  className="my-3"
                  src="../../../assets/images/wikis/pt/sysphar/FIG_21.png"
                  alt="FIG_21"
                />
                <p>
                  <figcaption className="figure-caption">
                    Figura 21. Exemplo de gráfico de floresta (meta-análise)
                  </figcaption>
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#80">
                <h3 className="fw-bold">8 Fase 5: publicação dos resultados da revisão</h3>
                <StaticImage
                  className="my-3"
                  src="../../../assets/images/wikis/pt/sysphar/FIG_22.png"
                  alt="FIG_22"
                />
                <p>
                  <figcaption className="figure-caption">
                    Figura 22. Fase 5: publicando os resultados da revisão
                  </figcaption>
                </p>
                <h3>8.1 Onde e como publicar?</h3>
                <p>
                  Existem diferentes estratégias para publicar, compartilhar e
                  divulgar resultados da revisão, incluindo eventos científicos,
                  workshops ou plataformas online e revistas nas quais as
                  publicações são revisadas por pares.{" "}
                </p>
                <p>
                  Em periódicos com revisão por pares, salvo algumas
                  especificações, o relatório da revisão sistemática e
                  meta-análise pode ser organizado como em outros tipos de
                  publicações. Alguns periódicos solicitam que os artigos enviem
                  uma {" "}
                  <a target="_blank" rel="noopener noreferrer"

                    href="http://www.prisma-statement.org/"
                  >
                    lista de verificação PRISMA
                  </a>
                  , para facilitar o processo de revisão por pares.{" "}
                </p>
                <p>A publicação pode ser organizada da seguinte forma:</p>
                <ul>
                  <li>
                    introdução: histórico teórico, hipótese e questão de
                    revisão;{" "}
                  </li>
                  <li>
                    métodos: brevemente apresentados, incluindo o número de
                    registro do protocolo, estratégia de pesquisa, estratégia de
                    triagem, extração de dados, avaliações de qualidade e
                    análise. É importante que cálculos e interpretações de
                    tamanhos de efeito devem ser explicitamente declarados,
                    porque têm impacto na discussão dos dados;{" "}
                  </li>
                  <li>
                    resultados: descrição dos resultados dos processos de busca
                    e triagem de estudos, apresentando:
                    <ul>
                      <li>o fluxograma PRISMA como figura do artigo; </li>
                      <li>
                        a descrição das qualidades das publicações incluídas na
                        revisão e sistematização dos resultados em tabelas, para
                        a discussão da validade externa dos estudos);{" "}
                      </li>
                      <li>
                        os resultados da análise de risco de viés dos estudos
                        incluídos na revisão, para a discussão da validade
                        interna dos estudos;{" "}
                      </li>
                      <li>
                        os resultados numéricos da meta-análise (estimativas de
                        tamanho de efeito combinado global com ou sem correção
                        pelo viés de publicação, estimativas de tamanho de
                        efeito combinado estratificados por subgrupos,
                        heterogeneidade das estimativas);{" "}
                      </li>
                      <li>
                        E a apresentação gráfica, para a qual em geral se usa o
                        gráfico de floresta para meta-análise e gráficos de
                        funil quando a avaliação do viés de publicação for
                        realizada.{" "}
                      </li>
                    </ul>
                  </li>
                  <li>
                    Discussão, na qual devem ser abordados:
                    <ul>
                      <li>
                        avaliação da qualidade interna e externa das publicações
                        incluídas;{" "}
                      </li>
                      <li>
                        os resultados da meta-análise (direção, magnitude e
                        heterogeneidade das estimativas de efeito combinado);{" "}
                      </li>
                      <li>
                        o impacto da qualidade dos estudos, do risco de vieses
                        experimentais, do risco de viés de publicação e da
                        heterogeneidade dos estudos sobre as estimativas de
                        tamanho do efeito combinado.
                      </li>
                    </ul>
                  </li>
                  <li>
                    conclusão: deve incluir a confiabilidade dos resultados da
                    revisão de acordo com as avaliações da qualidade,
                    heterogeneidade, viés de publicação. As limitações do
                    processo de revisão podem ser divulgadas.
                  </li>
                </ul>
              </Tab.Pane>
              <Tab.Pane eventKey="#90">
                <h3 className="fw-bold">9 Fase 6: mantendo a revisão sistemática viva{" "}</h3>
                <StaticImage
                  className="my-3"
                  src="../../../assets/images/wikis/pt/sysphar/FIG_23.png"
                  alt="FIG_23"
                />
                <p>
                  <figcaption className="figure-caption">
                    Figura 23. Fase 6: mantendo as revisões vivas
                  </figcaption>
                </p>

                <h3>9.1 Atualização da revisão sistemática</h3>
                <p>
                  As diretrizes existentes até ao momento (fevereiro de 2022)
                  orientam que revisões sistemáticas vivas sejam publicadas de
                  acordo com modelos semelhantes às publicações Cochrane.
                </p>
                <p>
                  Os modelos disponíveis são os seguintes:
                  <ul>
                    <li>
                      nova publicação a cada nova atualização (geralmente feita
                      em intervalos anuais), obtendo uma nova versão e DOI no
                      PubMed;{" "}
                    </li>
                    <li>
                      publicação em que a introdução e os métodos do manuscrito
                      principal não são alterados, apenas a seção de resultados.
                      Obs.: o manuscrito exigirá menos recursos para preparar no
                      segundo modelo, mas a versão original deve ter sido
                      escrita de maneira genérica para acomodar novas
                      informações emergentes em versões subsequentes;
                    </li>
                    <li>
                      publicação em que as descobertas das análises atualizadas
                      possam ser apresentadas como novos apêndices, sem
                      alteração no resumo ou manuscrito original. Obs.: esse
                      modelo torna difícil adicionar ou remover autores de
                      acordo com as mudanças em sua contribuição para as versões
                      subsequentes;
                    </li>
                    <li>
                      ou um modelo em que os resultados das atualizações são
                      resumidos em repositórios de dados de domínio público, que
                      apresentam os resultados atualizados visualmente. Obs.:
                      idealmente, esses repositórios devem ser interativos (por
                      exemplo: aplicativo{" "}
                      <a target="_blank" rel="noopener noreferrer"
                        href="https://abannachbrown.shinyapps.io/preclinical-models-of-depression/"

                      >
                        RShiny
                      </a>
                      ).{" "}
                    </li>
                  </ul>
                </p>
                <p>
                  Quando nenhum estudo novo é identificado para a inclusão,
                  apenas a data de pesquisa publicada precisa ser atualizada. Se
                  novos estudos forem identificados, é necessária uma nova
                  publicação.{" "}
                </p>
                <StaticImage
                  className="my-3"
                  src="../../../assets/images/wikis/pt/sysphar/FIG_24.png"
                  alt="FIG_24"
                />
                <p>
                  <figcaption className="figure-caption">
                    Figura 24. Fase 6: Fluxo de ações para revisões sistemáticas
                    e meta-análises vivas
                  </figcaption>
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="#100">
                <h3 className="fw-bold">10 Sobre</h3>
                <h3>10.1 SysPhar</h3>
                <p>
                  SysPhar (do inglês Systematic Pharmacology) é um recurso
                  produzido e oferecido gratuitamente pela CAMARADES BR cujo
                  desenvolvimento foi apoiado pela fundação Alexander von
                  Humboldt.{" "}
                </p>
                <p>
                  Neste guia SysPhar, reunimos e organizamos informações,
                  documentos, links e outras ferramentas que consideramos úteis
                  para ajudar farmacologistas a fazerem suas revisões
                  sistemáticas e meta-análises em Farmacologia.
                </p>
                <p>
                  Os recursos apresentados no SysPhar estão publicamente
                  disponíveis nas páginas de organizações como a CAMARADES,
                  Cochrane, OSF, PROSPERO, SYRCLE, PRISMA e outros. Agradecemos
                  a essas organizações e equipes por disponibilizarem esses
                  recursos pública e gratuitamente!
                </p>
                <p>
                  Este recurso foi atualizado pela última vez em: 07 de Agosto
                  de 2023.
                </p>

                <h3>10.2 Como citar a ferramenta?</h3>
                <p className="font-monospace">
                  SysPhar. Systematic Pharmacology, (Janeiro de 2022), CAMARADES
                  Brasil. {getPTCitation()}
                </p>

                <h3>10.3 Nossa equipe </h3>
                <h5>Versão em português e inglês:</h5>
                <ul>
                  <li>Juliana Aparecida Bolzan, MSc.</li>
                  <li>Cilene Lino de Oliveira, PhD.</li>
                  <li>Tamires Martins, MSc.</li>
                  <li> Sofia Diehl Döring, PharmB.</li>
                </ul>

                <Link to="/sobre-a-camarades#apoiadores">
                  <h3>10.4 Apoiadores</h3>
                </Link>
                <h3>10.5 Contato</h3>
                <p>
                  Se você tiver dúvidas sobre os recursos, quiser fazer uma
                  pergunta específica ou contribuir para o texto,{" "}
                  <Link to="/contato">entre em contato conosco aqui</Link>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Col>
          <Col sm={1}></Col>
        </Row>
      </Tab.Container>
    </section>
  )
}
